import React, {Component} from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from "./App";
import zhCN from 'antd/lib/locale/zh_CN';
import {BackTop, ConfigProvider} from "antd";
import Icons from "@components/Icons";

const style = {
    height: 40,
    width: 40,
    lineHeight: '40px',
    borderRadius: 4,
    backgroundColor: '#9f9f9f',
    color: '#fff',
    textAlign: 'center',
    fontSize: 40,
};


class Index extends Component {


    render() {

        return (
            <ConfigProvider locale={zhCN}>
                <App/>
                <BackTop>
                    <div style={style}><Icons type={'icon-huidaodingbu'}/></div>
                </BackTop>
            </ConfigProvider>
        );
    }
}


ReactDOM.render(<Index/>, document.getElementById('root'));