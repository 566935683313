import axios from "@components/service/request";
import cookie from "react-cookies";
import {v4 as uuidv4} from "uuid";

const baseURL = '/api/userInfo'

function updatePage(type) {
    let _uid = cookie.load("_uid");
    let uuid = uuidv4();
    if (_uid) {
        uuid = _uid
    } else {

        cookie.save("_uid", uuid);
    }

    let ip = cookie.load('ip');
    let sn = cookie.load('sn');
    let href = window.location.pathname;
    let user_info={
        visitId:uuid,
        userIp:ip,
        userSn:sn,
        visitPath:href,
        clientType:type
    }

    axios.post(baseURL + '/save', user_info);
    // let result = {}
    // await axios.get(baseURL + '/getData').then(res => {
    //     result = res;
    // }).catch(err => {
    //     message.error("未知错误，请联系管理员："+err)
    // })
    // return result.data
}

export {
    updatePage
}