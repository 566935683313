import React, {Component} from "react";
import {Menu} from "antd";
import {Link} from "react-router-dom";
import {setStorage, getStorage} from "@/utils/localstorage"
import Icons from "@components/Icons";
import './index.less'

class MenuModel extends Component {


    constructor(props) {
        super(props);
        this.handleSaveStorage = this.handleSaveStorage.bind(this)
    }

    componentDidMount() {

        let pathname = window.location.pathname;
        let arr = pathname.split('/');
        let key = getStorage('menuKey');
        switch (arr[1]) {
            case 'index':
                key = '1'
                break;
            case 'walkInZm':
                key = '2'
                break;
            case 'zmBusiness':
                key = '3'
                break;
            case 'zmWindows':
                key = '4'
                break;
            case 'garden':
                key = '5'
                break;
            case 'joinUs':
                key = '6'
                break;
            default:
                key = '1'
        }
        this.props.setCurrentKey(key)
    }


    handleSaveStorage  (e) {
        let url = e.target.getAttribute('url');
        let value = e.target.getAttribute('value');
        let userAgent = window.navigator.userAgent;
        if (userAgent.indexOf("baiduboxapp")!==-1){
            window.open(url,'_blank')
            // updatePage()
        }
        setStorage('menuKey', value)
        this.props.setCurrentKey(value)
    }

    render() {
        return (
            <Menu theme="light" mode="horizontal" defaultSelectedKeys={['1']} selectedKeys={[this.props.currentKey]}
                  overflowedIndicator={<Icons type={'icon-sanheng'}/>}
                  className='menu-box'
                  builtinPlacements={
                      {
                          bottomLeft:
                              {
                                  points: ['tc', 'bc'], // 子菜单的 "上中" 和 对应菜单的title "下中" 对齐。
                                  overflow: {
                                      adjustX: 1,
                                      adjustY: 1
                                  },
                                  // targetOffset:['30%','100%'],
                                  offset: [0, 5]
                              }
                      }
                  }
            >
                <Menu.Item key={'1'}>
                    <Link to="/index" url={'/index'} value={'1'} onClick={this.handleSaveStorage}>首页</Link>
                </Menu.Item>
                <Menu.Item key={'2'}>
                    <Link to="/walkInZm" url={'/walkInZm'} value={'2'} onClick={this.handleSaveStorage}>走进至名</Link>
                </Menu.Item>

                <Menu.SubMenu key={'3'} title={"至名业务"}>
                    <Menu.Item key="3:1">
                        <Link to="/zmBusiness/healthy"
                              url={'/zmBusiness/healthy'} value={'3'} onClick={this.handleSaveStorage}>至名健康</Link></Menu.Item>
                    {/*<Menu.Item key="3:1">*/}
                    {/*    <Link to="/zmBusiness/zmEcom" onClick={() => this.handleSaveStorage('3')}>至名电商</Link>*/}
                    {/*</Menu.Item>*/}
                    <Menu.Item key="3:2">
                        <Link to="/zmBusiness/seaPurchase"
                              url={'/zmBusiness/seaPurchase'} value={'3'} onClick={this.handleSaveStorage}>至名海购</Link>
                    </Menu.Item>
                    <Menu.Item key="3:3">
                        <Link to="/zmBusiness/crossBorder"
                              url={'/zmBusiness/crossBorder'} value={'3'} onClick={this.handleSaveStorage}>思勉跨境</Link>
                    </Menu.Item>
                    {/*暂时隐藏*/}
                    {/*<Menu.Item key="3:4">
                        <Link to="/zmBusiness/crowd" onClick={() => this.handleSaveStorage('3')}>君达电商</Link>
                    </Menu.Item>*/}
                </Menu.SubMenu>
                <Menu.Item key={'4'}>
                    <Link to="/zmWindows" url={'/zmWindows'} value={'4'} onClick={this.handleSaveStorage}>至名之窗</Link>
                </Menu.Item>
                <Menu.Item key={'5'}>
                    <Link to="/garden" url={'/garden'} value={'5'} onClick={this.handleSaveStorage}>党建园地</Link>
                </Menu.Item>
                <Menu.SubMenu key={'6'} title={"加入我们"}>
                    <Menu.Item key="6:1">
                        <Link to="/joinUs/welfare"
                              url={'/joinUs/welfare'} value={'6'} onClick={this.handleSaveStorage}>福利待遇</Link></Menu.Item>

                    <Menu.Item key="6:2">
                        <Link to="/joinUs/jobInfo"
                              url={'/joinUs/jobInfo'} value={'6'} onClick={this.handleSaveStorage}>岗位信息</Link></Menu.Item>
                </Menu.SubMenu>

            </Menu>
        );
    }
}

export default MenuModel;
