import './App.less';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import {commonRoutes} from "./routers";
import React from "react";
import FrameOut from "./components/layout";

import AOS from 'aos';
import 'aos/dist/aos.css';


function App() {

    AOS.init();
    return (
        <div className="App">
            <BrowserRouter>
                <FrameOut>
                    <Routes>
                        {/*公共的路由*/}
                        {
                            commonRoutes.map(item => {
                                return (<Route path={item.pathName} element={<item.component/>} key={item.pathName}/>)
                            })
                        }
                        <Route path="/" element={<Navigate to="/index"/>}/>
                        <Route path="*" element={<Navigate to="/404"/>}/>
                    </Routes>
                </FrameOut>
            </BrowserRouter>
        </div>
    );
}

export default App;
