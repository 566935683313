import Loadable from 'react-loadable'
import Loading from '@components/Loading'

// 路由懒加载

const Admin = Loadable({
    loader: () => import("./Admin"),
    loading: Loading,
})

const WalkInZm = Loadable({
    loader: () => import("./walkInZm"),
    loading: Loading,
})

const ZmEcom = Loadable({
    loader: () => import("./zmBusiness/zmEcom"),
    loading: Loading,
})
const CrossBorder = Loadable({
    loader: () => import("./zmBusiness/crossBorder"),
    loading: Loading,
})

const Healthy = Loadable({
    loader: () => import("./zmBusiness/healthy"),
    loading: Loading,
})

const Crowd = Loadable({
    loader: () => import("./zmBusiness/crowd"),
    loading: Loading,
})
const SeaPurchase = Loadable({
    loader: () => import("./zmBusiness/seaPurchase"),
    loading: Loading,
})

const ZmWindows = Loadable({
    loader: () => import("./zmWindows"),
    loading: Loading,
})
const Welfare = Loadable({
    loader: () => import("./joinUs/welfare"),
    loading: Loading,
})

const JobInfo = Loadable({
    loader: () => import("./joinUs/jobInfo"),
    loading: Loading,
})

const Garden = Loadable({
    loader: () => import("./garden"),
    loading: Loading,
})

const NotFound = Loadable({
    loader: () => import("./notFound"),
    loading: Loading,
})


export {
    Admin,
    WalkInZm,
    ZmEcom,
    Healthy,
    Crowd,
    ZmWindows,
    Welfare,
    JobInfo,
    Garden,
    SeaPurchase,
    CrossBorder,
    NotFound
}
