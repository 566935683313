import React, {Component} from 'react';

import {Col, Image, Layout, Row} from "antd";
import './index.less'
import logo from './images/logo.png'
import {Link} from "react-router-dom";
import gongan from './images/gongan.png'
import code from './images/code.jpg'
import { setStorage} from "@/utils/localstorage";

const {Footer} = Layout;


class Index extends Component {

     handleToGov = () => {
        window.open("http://beian.miit.gov.cn");
    }


     handleSaveStorage = (value) => {
        setStorage('menuKey', value)
        this.props.setCurrentKey(value)
    }

    render() {
        return (
            <Footer style={{textAlign: 'center'}} className={'footer-box'}>
                <Row className={'footer-box-row'}>
                    <Col span={10} className={'left-info'}>
                        <h2 className={'logo'}>
                            <Image src={logo} width={'10vw'} preview={false}/>
                        </h2>
                        <div className={'slogan'}>忠诚、务实、勤奋、创新</div>
                        <span>地址：湖南省长沙市天心区长沙总部基地14-A、D栋</span>
                        <br/>
                        <span>电话：0731-89718783、0731-88911377</span>
                        <br/>
                        <span>商务合作：13787215593</span>
                        <br/>
                        <span>邮箱：zm_team@126.com、zm@zm-team.cn</span>
                    </Col>
                    <Col span={4}>
                        <h2 className={'title'}>
                            至名业务
                        </h2>
                        <div>
                            <Link to={'/zmBusiness/healthy'} onClick={()=>this.handleSaveStorage('3')}>至名健康</Link>
                            <Link to={'/zmBusiness/seaPurchase'} onClick={()=>this.handleSaveStorage('3')}>至名海购</Link>
                            <Link to={'/zmBusiness/crossBorder'} onClick={()=>this.handleSaveStorage('3')}>思勉跨境</Link>
                            {/*<Link to={'/zmBusiness/crowd'} onClick={()=>this.handleSaveStorage('3')}>君达电商</Link>*/}
                        </div>
                    </Col>
                    <Col span={4}>
                        <h2 className={'title'}>
                            至名资讯
                        </h2>
                        <div>
                            <Link to={'/zmWindows'} onClick={()=>this.handleSaveStorage('4')}>至名之窗</Link>
                            <Link to={'/garden'} onClick={()=>this.handleSaveStorage('5')}>党建园地</Link>
                            <Link to={'/joinUs/welfare'} onClick={()=>this.handleSaveStorage('6')}>福利待遇</Link>
                            <Link to={'/joinUs/jobInfo'} onClick={()=>this.handleSaveStorage('6')}>岗位信息</Link>
                        </div>
                    </Col>
                    <Col span={6}>
                        <h2 className={'title code'}>
                            <Image src={code} width={120}/>
                            <p style={{fontSize:12,color:'#999'}}>请扫描关注</p>
                        </h2>
                    </Col>
                </Row>
                <Row className={'footer-box-info'}>
                    <Col span={24} xl={0} lg={0} md={0} sm={24} xs={24}>
                        <span>地址：湖南省长沙市天心区长沙总部基地14-A、D栋</span>
                        <br/>
                        <span>电话：0731-89718783、0731-88911377</span>
                        <br/>
                        <span>商务合作：13787215593</span>
                        <br/>
                        <span>邮箱：zm_team@126.com、zm@zm-team.cn</span>
                    </Col>
                </Row>
                <Row className={'record-info'}>
                    <Col span={24} >
                        <div onClick={this.handleToGov} style={{cursor: "pointer"}}>
                            <Image src={gongan} preview={false}/>
                            ICP备案号：湘ICP备19013447号-1
                        </div>
                    </Col>
                </Row>
            </Footer>
        )

    }
}

export default Index
