import React, {Component} from "react";
import {Layout, Row, Col, Image} from 'antd';
import Logo from "@assets/images/logo.png";
import Logo2 from "@assets/images/logo2.png";
import Logo3 from "@assets/images/logo3.png";
import './index.less'
import MenuModel from "./Menu"
import FooterModel from "./Footer"
import {updatePage} from "@/api/infoCollect";

const {Header, Content} = Layout;

class FrameOut extends Component {

    state = {
        currentKey: 1
    }

    setCurrentKey=(key)=>{
        window.scrollTo(0,0)
        let type=this.browserRedirect()
        this.setState({
            currentKey:key
        },()=>{
            updatePage(type)
        })
    }

    browserRedirect=()=> {
        var sUserAgent = navigator.userAgent.toLowerCase();
        if (/ipad|iphone|midp|rv:1.2.3.4|ucweb|android|windows ce|windows mobile/.test(sUserAgent)) {
            //跳转移动端页面
            return "mobile"
            //this.mobileStatus = mobile(页面通过data的mobileStatus 值做不同样式)
        } else {
            //跳转pc端页面
            return "pc"
            //this.mobileStatus = pc
        }
    }

    render() {
        return (
            <Layout className="layout">
                <Header style={{backgroundColor: "#fff",position: 'fixed', zIndex: 1, width: '100%'}}>
                    <Row justify={'space-between'}>
                        <Col lg={12} xs={0} push={4}>
                            <div className="logo">
                                <Image src={Logo3} preview={false} id={'logo-img'}/>
                            </div>
                        </Col>
                        <Col id={'alt-logo'}>
                            <div className="logo">
                                <Image src={Logo2} width={'10%'} alt={"湖南至名品牌管理有限公司"} preview={false}/>
                            </div>
                        </Col>
                        <Col lg={0} xs={12}>
                            <div className="logo">
                                <Image src={Logo} alt={"湖南至名品牌管理有限公司"}  preview={false}/>
                            </div>
                        </Col>
                        <Col lg={12} md={12} sm={0} xs={0} pull={2}>
                            <MenuModel setCurrentKey={this.setCurrentKey} currentKey={this.state.currentKey}/>
                        </Col>
                        <Col lg={0} md={0} sm={2} xs={2}>
                            <MenuModel setCurrentKey={this.setCurrentKey} currentKey={this.state.currentKey}/>
                        </Col>
                    </Row>
                </Header>
                <Content style={{marginTop:64}}>
                    <div className="site-layout-content">{this.props.children}</div>
                </Content>
                <FooterModel setCurrentKey={this.setCurrentKey}/>
            </Layout>
        );
    }
}

export default FrameOut;
