import {
    Admin,
    Healthy,
    Welfare,
    NotFound,
    WalkInZm,
    ZmWindows,
    JobInfo,
    Crowd,
    Garden,
    SeaPurchase, CrossBorder
} from "@/pages";

const commonRoutes = [
    {
        pathName: '/index',
        component: Admin
    },
    {
        pathName: '/walkInZm',
        component: WalkInZm
    },
    {
        pathName: '/zmBusiness/crossBorder',
        component: CrossBorder
    },
    {
        pathName: '/zmBusiness/healthy',
        component: Healthy
    },
    {
        pathName: '/zmBusiness/crowd',
        component: Crowd
    },
    {
        pathName: '/zmBusiness/seaPurchase',
        component: SeaPurchase
    },
    {
        pathName: '/zmWindows',
        component: ZmWindows
    },
    {
        pathName: '/joinUs/welfare',
        component: Welfare
    },
    {
        pathName: '/joinUs/jobInfo',
        component: JobInfo
    },
    {
        pathName: '/garden',
        component: Garden
    },
    {
        pathName: '/404',
        component: NotFound
    }
]
export {
    commonRoutes
}